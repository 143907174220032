ul {
  list-style-type: disc;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 32px;
}
.imgWrapper img {
  width: 100%;
  height: auto;
}
.imgSMWrapper img {
  width: 60%;
  height: auto;
}
.mentionWrapper {
  background-color: #F1F1F1;
  border-left: 8px solid #3E9337;
  padding: 24px;
}
.mentionWrapper i {
  font-size: 14px;
}
.detailWrapper a {
  color: #3E9337;
  cursor: pointer;
}
.detailWrapper a:hover {
  color: #0B3137;
}

@media screen and (max-width: 768px) {
  .imgSMWrapper img {
    width: 100%;
  }
}